<template>
  <table class="cs-table">
    <slot />
  </table>
</template>

<style lang="scss" scoped>
  @import "./cs-table";

  .cs-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
  }
</style>
