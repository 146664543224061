<template>
  <tr class="cs-tr">
    <slot />
  </tr>
</template>

<style lang="scss" scoped>
  .cs-tr {
    color: $gray-text;
    &:nth-child(2n) {
      background: $gray-tint;
    }
  }
</style>
