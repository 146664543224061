<template>
  <component
    :is="isHead ? 'th' : 'td'"
    :class="['cs-td', `cs-td-${align}`, isHead && 'cs-tdth']"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    props: {
      isHead: {
        type: Boolean,
        default: false,
      },
      align: {
        type: String,
        default: "left",
        validator: (value) => {
          // The value must match one of these strings
          return ["left", "center", "right"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cs-td {
    padding: $base-spacing * 2 $base-spacing * 4;
    color: $gray-text;
    &.cs-tdth {
      padding: $base-spacing * 4;
      border-bottom: 2px solid $gray-200;
      color: inherit;
    }
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
  }
</style>
